.notification-btn-dismiss {
  background: white;
  border: 1px solid #cdcfce;
  color: #4f5655;
  padding: 0 2.5em;
}
.notification-addon-modal {
  padding: 2rem;
}
.notification-addon-antrow {
  margin-top: 2.2rem;
}
