.import-export-wrapper {
  &_head {
    font-weight: 700;
    font-size: 18px;
    color: #040e0c;
  }
  &_sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    color: #4f5655;
  }
  &_cards-wrapper {
    margin: 28px 0;
  }
}

.import-excel-wrap {
  padding: 12px 24px;
  width: 420px;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #e6e7e7;

  &_status-in-progress {
    width: 110px;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 4px 16px;
    background: #fff0db;
    border-radius: 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-transform: uppercase;
    color: #e6a145;
    float: right;
  }

  &_import-text-area {
    padding: 12px 0 0;
    padding-right: 10px;
    // margin-bottom: 22px;
    &_head {
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      color: #000000;
    }
    &_sub {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;
      color: #4f5655;
    }
  }

  &_view-details {
    padding: 8px 16px;
    margin-top: 12px;
    height: 40px;
    cursor: 'pointer';
  }
  &_upload {
    min-width: '100px';
    max-width: 'fit-content';
    border: 1px solid #6dada2;
    padding: 9px 26px;
    margin-top: 12px;
    background-color: #f0f8f6;
    color: #0b7764;
    height: 40px;
    cursor: 'pointer';
  }

  &_fileTag {
    display: none;
  }

  .info {
    background: #fff0db;
    border-radius: 4px;
    padding: 5px 10px;
    width: 100%;
    margin: 10px 0;
  }

  .cardIcon {
    height: 100%;
    padding-top: 16px;
  }
}

.importWrapper {
  &_head {
    font-weight: 700;
    font-size: 18px;
    color: #040e0c;
  }
  &_sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4f5655;
  }
  &_cardsWrapper {
    margin: 28px 0;
  }
  &_closeDiv {
    cursor: pointer;
  }
  &_closeIcon {
    margin-right: 8px;
    margin-bottom: 2px;
    width: 17px;
    height: 17px;
  }
  &_cnclBtn {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #686e6d;
  }
}

.validationStep {
  position: relative;
  padding: 10px 10px;
  &_imgStatus {
    margin-bottom: 13px;
  }
  &_processLine {
    height: 50px;
    width: 1px;
    margin: 0;
    margin-left: 15px;
    margin-bottom: 5px;
    border: 1px solid #e6e7e7;
  }
  &_processLineRemove {
    height: 0px;
    width: 0px;
    margin: 0;
    border: 0px solid #e6e7e7;
  }
  &_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    color: #000000;
    padding-bottom: 5px;
  }
  &_subText {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #4f5655;
  }
  &_extraDesc {
    margin-top: 5px;
    cursor: pointer;
  }
  &_extraDescShow {
    margin-top: 10px;
    background: #e6e7e7;
    border-radius: 8px;
    padding: 16px;
  }
  &_extraDescList {
    padding: 0 8px;
  }
  &_extraSubText {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #0b7764;
    margin-bottom: 16px;
  }
  &_status {
    position: absolute;
    align-items: flex-end;
    right: 16px;
    top: 16px;
  }
  &_statusDone {
    padding: 4px 16px;
    background: #f0f8f6;
    border-radius: 32px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #0b7764;
    float: right;
  }
  &_statusInProgress {
    width: 110px;
    padding: 4px 16px;
    background: #fff0db;
    border-radius: 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-transform: uppercase;
    color: #e6a145;
    float: right;
  }
  &_statusFailed {
    padding: 4px 16px;
    background: #fff0db;
    border-radius: 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-transform: uppercase;
    color: #f1f7ff;
    float: right;
    background: #f34d4d;
    border-radius: 32px;
  }
  &_downloadSheet {
    width: 46%;
    height: 40px;
    background: #1d2624;
    border-radius: 4px;
    color: #ffffff;
  }
  &_downloadSheet:hover {
    width: 46%;
    height: 40px;
    background: #1d2624;
    border-radius: 4px;
    color: #ffffff;
  }
  &_downloadSheet:focus {
    width: 46%;
    height: 40px;
    background: #1d2624;
    border-radius: 4px;
    color: #ffffff;
  }
  &_uploadAgain {
    width: 46%;
    height: 40px;
    background: #f0f8f6;
    border: 1px solid #6dada2;
    border-radius: 4px;
    color: #0b7764;
  }
}

.validationStepInProgress {
  padding: 20px 10px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 5px;
}

.exportExcelWrap {
  background: #ffffff;
  border: 1px solid #e6e7e7;
}

.export-bulk-excel-sheet
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-body {
  padding: 0px;
}

.sortableList {
  z-index: 1000;
  cursor: grab !important;
}

.downLoadBtnCss {
  background: #000000;
  color: white;
}
.download-excel-modal .ant-modal-body {
  padding: 0px;
}
.notification-pannel {
  background: #ffffff;
  border-radius: 8px;
  font-weight: 700;
}
.bulk-excel-modal-img {
  border-radius: 8px 8px 0px 0px;
}
.download-excel-modal-title {
  width: 100%;
  padding: 1.5rem;
  gap: 1rem;
}
.title-style {
  font-size: 16px;
  font-weight: 700;
}
.excel-export-divider {
  border: 0.5px dashed #e6e7e7;
}
.excel-card-width {
  width: 100%;
}
.excel-card-title {
  font-weight: 700;
}
.excel-card-text {
  color: #0b7764;
  font-weight: 700;
  font-size: 14px;
  margin-top: 4;
  cursor: pointer;
}

.excel-card-select-products {
  width: 100%;
  margin-top: 1em;
}
.excel-card-selected-products {
  font-weight: 700;
}
.excel-card-button {
  font-size: 16px;
}
.close-button {
  padding: 0 2rem;
  font-size: 16px;
  padding: 0 1rem;
  height: 2.5em;
  width: 160px;
  text-shadow: none;
  box-shadow: none;
}

.create-excel-template-container {
  position: absolute;
  width: 100%;
  bottom: 10px;
  border-top: 1px solid #e6e7e7;
  padding: 10px;
  background: #ffffff;
  height: 7.5%;
  top: 92.5%;
}
.excel-card-template-button {
  margin-left: 1em;
}
.excel-export-footer-button {
  padding: 0 2rem;
}
.excel-new-template-notification {
  color: #4989e9;
  font-size: 20px;
}
.excel-create-template-header-container {
  background: #f5f5f5;
  padding: 2rem 2rem 0rem 2rem;
  height: 100%;
}
.excel-create-template-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.6;
}
.excel-create-template-text {
  color: #4f5655;
}
.height {
  height: 100%;
}
.create-template-title {
  padding-left: 2rem;
  margin-top: 1.8rem;
}
.drawer-header {
  font-weight: 700;
  font-size: 18px;
}
.create-template-padding {
  padding-left: 2rem;
}
.create-template-error {
  color: #f34d4d;
}
.create-template-name {
  margin-top: 0.5em;
  padding-left: 2rem;
  margin-right: 2rem;
}
.create-template-error-text {
  color: #f34d4d;
  line-height: 1.6;
  font-weight: 700;
}
.create-template-attributes {
  padding-left: 2rem;
  height: 67.5%;
  margin-right: 2rem;
}
.create-template-empty-card {
  width: 100%;
  padding: 10%;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 2em;
  height: 84%;
  overflow: hidden;
  padding-bottom: 1rem;
}
.excel-container-width {
  width: 100%;
}
.create-template-empty-title {
  height: 84%;
  overflow: hidden;
}
.create-template-empty-text {
  margin-top: 1rem;
  height: 3%;
}
.create-template-footer {
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
  background: #ffffff;
  border-top: 1px solid #e6e7e7;
  height: 7%;
  position: absolute;
  bottom: 2px;
}
.template-detail-container {
  width: 100%;
  height: 97%;
  flex-direction: row;
}
.view-attributes {
  margin-top: 1rem;
  width: 100%;
}
.selected-template {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
}
.view-attributes-list {
  margin-top: 1rem;
  overflow: scroll;
  height: 84%;
  width: 100%;
}
.attribute-listing {
  margin-top: 1rem;
  width: 100%;
  height: 1rem;
}
.view-details-header {
  padding: 1.5rem;
  width: 100%;
  height: 89%;
}
.total-templates {
  margin-top: 2rem;
  width: 100%;
}
.create-new-template {
  color: #0b7764;
  font-weight: 700;
  cursor: pointer;
}
.change-template-container {
  margin-top: 2rem;
  width: 100%;
  gap: 1rem;
  overflow-y: scroll;
  height: 85%;
  display: flex;
  flex-direction: column;
}
.change-template-container-with-url {
  margin-top: 2rem;
  height: 50%;
  width: 100%;
  gap: 1rem;
  overflow-y: scroll;
  height: 74%;
  display: flex;
  flex-direction: column;
}
.template-card-inactive {
  border-radius: 8px;
  padding: 1rem;
  gap: 0.2rem;
  width: 100%;
  max-height: 140px;
  background: #ffffff;
  border: 1px solid #e6e7e7;
  flex-direction: column;
}
.template-card-active {
  border-radius: 8px;
  padding: 1rem;
  gap: 0.2rem;
  width: 100%;
  max-height: 150px;
  flex-direction: column;
  background: #f0f8f6;
  border: 1px solid #0b7764;
}

.default-icon {
  margin-top: 0.2rem;
  color: #1b6be4;
}
.default-tag-container {
  margin-left: 0.5rem;
}
.default-tag-container-text {
  color: #1b6be4;
  font-weight: 700;
  font-size: 12px;
}
.change-template-name {
  color: #000000;
  font-weight: 700;
}
.change-template-icon {
  color: #0b7764;
  position: relative;
  top: 0.2rem;
  left: 0.5rem;
}
.delete-template {
  box-shadow: none;
  border: none;
  padding: 0px;
  cursor: pointer;
}
.template-button {
  color: #4f5655;
  font-weight: 700;
  padding: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.template-default-button {
  color: #0b7764;
}
.template-details-container {
  width: 100%;
  padding: 1.5rem;
  background: #f5f5f5;
  height: 100%;
}
.template-button-text {
  height: 3%;
}
.delete-template-modal {
  width: 100%;
  padding: 1.5rem;
  gap: 1rem;
}
.modal-template-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
}
.delete-template-text {
  font-size: 16px;
  line-height: 1.5;
  color: #4f5655;
}
.delete-template-container {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
}
.delete-template-confirmation {
  position: relative;
  bottom: 3px;
}
.delete-template-footer {
  width: 100%;
  margin-top: 1rem;
}
.delete-template-button {
  background: #ffffff;
  color: #4f5655;
  font-size: 14px;
  border: 1px solid #cdcfce;
  padding: 0rem 2rem;
}
.delete-template-active {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  padding: 0rem 2rem;
  font-size: 14px;
  color: #f34d4d;
  border: 1px solid #f34d4d;
  background: #ffffff;
}

.delete-template-inactive {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  padding: 0rem 2rem;
  font-size: 14px;
  color: #9b9f9e;
  border: 1px solid #cdcfce;
  background: #e6e7e7;
}

.discard-modal {
  width: 100%;
  padding: 1.5rem;
  gap: 1rem;
}
.discard-modal-text {
  font-size: 16px;
  line-height: 1.5;
  color: #4f5655;
}
.discard-modal-footer {
  width: 100%;
  margin-top: 1rem;
}

.import-drawer-footer-button {
  position: absolute;
  bottom: 1.25rem;
}
.import-drawer-divider {
  position: absolute;
  bottom: 3.5rem;
}
.open-notification-text {
  color: #040e0c;
  font-weight: 700;
  line-height: 1.7;
  font-size: 14px;
}
.select-attribute-container {
  width: 100%;
  margin-top: 2rem;
  height: 95%;
}
.select-attribute-list {
  height: 100%;
  overflow: scroll;
}
.select-attribute-divider {
  margin: 0px;
}
.checkbox-container {
  margin-bottom: 0.5rem;
}
.select-attribute-checkbox {
  position: relative;
  bottom: 4px;
}
.custom-checkbox-container {
  margin-top: 1rem;
}
.sortable-container-head {
  margin-top: 1rem;
  height: 5%;
}
.sortable-list-container {
  width: 100%;
  margin-top: 0.5rem;
  overflow-x: scroll;
  height: 90.5%;
}
.sortable-item-container {
  z-index: 1000;
  margin-top: 1rem;
  cursor: grab;
  width: 100%;
}
.bulk-upload-sortable-item-container {
  z-index: 1000;
  margin-top: 1rem;
  cursor: grab;
  width: 86%;
}
.sortable-item-text {
  margin-left: 0.5rem;
}
.sortable-item-button-container {
  display: flex;
}
.sortable-item-button {
  border: none;
  box-shadow: none;
  padding: 0px;
}
.notification-icon {
  color: #0b7764;
  font-size: 20px;
}
.template-container {
  height: 100%;
}
.attribute-container {
  height: 87.5%;
}
.sortable-component-text {
  text-align: center;
}
.change-template-description {
  margin-top: 0.5rem;
}
.default-template-button-container {
  margin-left: 1rem;
}
.custom-tag-container-text {
  font-weight: 700;
  font-size: 12px;
  color: #1b6be4;
  padding: 3px;
  border-radius: 4px;
}
.custom-tag {
  width: 25%;
  flex-direction: row;
  display: flex;
}
.default-tag {
  width: 40%;
  flex-direction: row;
  display: flex;
}

.excel-export-change-template {
  padding: '32px';
}

// import validation failed

.validation-failed {
  &_headDiv {
    text-align: center;
  }
  &_head {
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #040e0c;
  }
  &_cause-steps {
    width: 100%;
    background: #f2f3ec;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0 32px 0;
    &_flexDiv {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 18px;
      &_img {
        margin-right: 15px;
      }
      &_step-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 200%;
        color: #4f5655;
      }
    }
  }
  &_close-cta-div {
    margin-top: 16px;
    &_close-cta {
      height: 40px;
      padding: 8px 16px;
      background: #1d2624;
      border-radius: 4px;
      color: #ffffff;
    }
    &_close-cta:hover {
      height: 40px;
      padding: 8px 16px;
      background: #1d2624;
      border-radius: 4px;
      color: #ffffff;
    }
    &_close-cta:focus {
      height: 40px;
      padding: 8px 16px;
      background: #1d2624;
      border-radius: 4px;
      color: #ffffff;
    }
  }
}

.cancel-import {
  &_head {
    margin: 0;
    color: #000000;
  }
  &_desc {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    margin: 14px 0;
    color: #4f5655;
  }
  &_check {
    margin-top: 14px;
    &_rw {
      cursor: pointer;
    }
    &_cnfrm {
      margin-right: 10px;
    }
    &_txt {
      margin-top: 4px;
    }
  }
  &_footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &_cancel {
    padding: 0 40px;
    height: 40px;
  }
  &_continue {
    padding: 0 40px;
    height: 40px;
  }
}

.excel-limit-note {
  width: 100%;
  margin-top: 12px;
  padding: 10px 12px;
  background: #fff0db;
  border-radius: 4px;
  &_img {
    margin-right: 10px;
  }
  &_txt {
    font-weight: 400;
    font-size: 14px;
    color: #040e0c;
  }
}

.notification-container {
  margin-bottom: 16px;
}

.notification-sm-text {
  font-size: 12px;
}

.notification-body {
  width: 100%;
}

.notification-lg-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.notification-md-text {
  font-size: 14px;
}

.notication-img {
  margin-right: 10px;
}

.notification-btn {
  background: #1d2624;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 16px;
  height: 40px;
  float: right;
}

.notification-status {
  margin-bottom: 8px;
}

.validation_error {
  margin-left: 4px;
}

.import-export-title {
  height: 3%;
}
.temnplate-name-error-container {
  height: 2%;
}
.create-template-container {
  height: 100%;
  overflow: hidden;
}
.create-template-empty-card-title {
  height: 3%;
}

.footer-div {
  width: 100%;
  padding: 40px 0;
  background-color: #ffffff;
  border-top: 1px solid #e6e7e7;
  position: absolute;
  bottom: 0rem;
}

.footer-div-btn {
  position: absolute;
  bottom: 1.25rem;
  height: 2.5em;
  width: 160px;
}

// export
.export-download-cta {
  background: #f0f8f6;
  border: 1px solid #6dada2;
  border-radius: 4px;
  color: #0b7764;
  height: 40px;
}

.updateImageCta {
  padding: 12px 0;
}

.export-create-template-cta {
  height: 40px;
  background: #ffffff;
  border: 1px solid #cdcfce;
  border-radius: 4px;
  color: #4f5655;
}

.custom-export-main {
  padding: 12px 24px;
}
.excel-export-card-footer-container {
  display: contents;
}
.standard-button {
  padding: 0 1rem;
  height: 2.5em;
  width: 160px;
  text-shadow: none;
  box-shadow: none;
}

@primary-color: #278977;