.ack-header {
  font-size: 20px;
  font-weight: bold;
  color: #040e0c;
}

.ack-subheader {
  color: #686e6d;
  font-size: 14px;
  margin-top: 8px;
}

@primary-color: #278977;