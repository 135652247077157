@media screen and (max-width: 1023px) {
  .hideOnSmall {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .displayOnSmall {
    display: none;
  }
}

.alignCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
