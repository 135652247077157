.actionRow {
  justify-content: center;
}

.btnCloseModal {
  background: black;
  color: white;
}

.downloadNotification {
  /* position: fixed; */
  /* top: 80px; */
  /* right: 20px; */
  cursor: pointer;
  /* width: 500px; */
  padding: 0px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.downloadGif {
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.downloadNotification .details {
  padding: 18px;
}

.downloadNotification .details .user {
  font-size: 11px;
  padding-bottom: 5px;
}

.details .closeIcon {
  position: absolute;
  right: 0;
}

.details .downloadBtn {
  padding-top: 30px;
  cursor: pointer;
}

.downloadNotification .details .catalogueName {
  font-size: 14px;
  /* align-items: "center" */
}

.downloadNotification .details .fileType {
  font-weight: 700;
  font-size: 14px;
}
.downloadNotification.visible {
  display: block;
}
