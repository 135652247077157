.text-plan-cards {
  margin-top: 0.5rem;
}
.complete-plan-div {
  border-bottom: 1px solid #b4b7b6;
  width: 25%;
  padding: 0.4rem;
  cursor: pointer;
  margin: auto;
}
.upgrade-cards-row {
  margin-top: 2.3rem;
}
.upgrade-text-small {
  margin-top: 0;
  color: #4f5655;
  font-size: 0.75rem;
}
.upgrade-btn-send {
  margin-top: 1rem;
}
.upgrade-row-benefits {
  margin-bottom: 0.8rem;
}
.upgrade-plan-cards {
  border: 1px solid #cdcfce;
  border-radius: 0.4rem;
  width: 11rem;
  min-height: 22rem;
}
.upgrade-modal-subtext {
  color: #4f5655;
}
.upgarde-plan-modal-divider {
  margin: 0.6rem 0 0.8rem 0;
}
.plan-upgrade-loading-col {
  width: 10rem;
  height: 24rem;
}
.upgrade-plan-support-text {
  font-weight: bold;
}
.upgrade-wrapper-button-disabled {
  cursor: not-allowed;
}
.upgrade-wrapper-button-active {
  cursor: pointer;
}
