//font-color
@change: #0b7764;
@pending-red: #c23e3e;
@pending-red-bgd: #fddbdb;
//component
@service-black: #040e0c;
@service-gray: #4f5655;
@amount-black: #14453c;
//sub-component
@sub-gray: #363e3d;
@sub-black: #1d2624;
//change-component
@change-gray: #686e6d;
@change-label-gray: #828786;

//font-size
@xlarge: 20px;
@large: 18px;
@medium: 16px;
@small: 14px;
@xsmall: 12px;

//font-weight
@bold: 700;
@regular: 400;

@media (max-width: 400px) {
  .dashboard__title {
    margin-left: 3.5em;
  }
}
.dashboard__title {
  color: @sub-black;
  font-weight: 400;
  font-size: 13px;
  font-family: 'SatoshiMedium';
}

.text {
  font-size: @medium;
  font-weight: @bold;
}

.text-reg {
  font-size: @large;
  font-weight: @regular;
}

.bold-text {
  font-size: @large;
  font-weight: @bold;
}

.text-desc {
  color: @service-gray;
  font-size: @xsmall;
  font-weight: @regular;
}

.claim-text {
  font-size: @small;
  font-weight: @bold;
  color: @change;
}

.claim-status {
  font-size: @xsmall;
  font-weight: @bold;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
}

.spacing {
  margin: 0.2rem 0;
}

//reward voucher card
.reward {
  border-radius: 8px;
  background: linear-gradient(
    to right,
    rgb(201, 228, 223, 0.8),
    rgb(255, 232, 202)
  );
  margin-right: 1rem;
  margin-bottom: 1rem;

  .reward-text {
    font-size: @small;
    font-weight: @regular;
    color: #000000;
    padding: 1.75rem;
  }

  .text {
    font-size: @medium;
    font-weight: @bold;
  }

  .reward-title {
    font-size: 24px;
    font-weight: 800;
    font-family: 'SatoshiBold', 'SatoshiMedium';
  }

  .btn {
    font-weight: @bold;
    color: #ffffff;
    background-color: @sub-black;
    margin-top: 1rem;
    border: none;
  }

  .offer {
    position: absolute;
    left: 0;
    top: 0.75rem;
    font-size: @xsmall;
    background-color: #ffd194;
    padding: 1rem;
    padding-left: 1.75rem;
    height: 1.75rem;
    align-content: center;
    border-radius: 4px;
  }
}

//reward card
.total-card {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  background: #f5f5f5;

  .text-bold {
    font-size: @xlarge;
    font-weight: @bold;
  }

  .text {
    font-size: @medium;
    font-weight: @regular;
  }

  .text-desc {
    color: @service-gray;
    font-size: @xsmall;
    font-weight: @regular;
  }

  .spacing {
    margin: 0.6rem;
  }
}

//display card
.reward-card {
  margin: 1rem;
  border-radius: 8px;
  background: linear-gradient(to right, #c9e4df, #ffe8ca);

  .spacing {
    margin: 0.6rem;
  }

  .reward-title {
    font-size: 24px;
    font-weight: 800;
    font-family: 'SatoshiBold', 'SatoshiMedium';
  }

  .text {
    font-size: @medium;
    font-weight: @bold;
  }

  .text-desc {
    color: #4f5655;
    font-size: @medium;
  }

  .btn {
    font-weight: @bold;
    color: #ffffff;
    background-color: #1d2624;
    margin-top: 1rem;
    border: none;
  }

  .view-details {
    background-color: transparent;
    border: none;
    color: #0b7764;
    font-size: @small;
    font-weight: @bold;
    padding: 0.25rem;
  }
}

//gift card
.gift-card {
  border-radius: 8px;
  background: linear-gradient(to right, #c9e4df, #ffe8ca);

  .title {
    color: #0c2924;
    font-size: 22px;
    font-weight: @bold;
  }

  .desc {
    color: @service-gray;
    font-size: @medium;
    font-weight: @regular;
    justify-content: center;
  }

  .btn {
    font-weight: @bold;
    width: 16rem;
    height: 3rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    color: #ffffff;
    background-color: @sub-black;
    border: none;
  }

  .text {
    font-size: @small;
    font-weight: @bold;
    color: #000000;
    padding-top: 4rem;
    justify-content: center;
  }

  .label {
    font-size: @small;
    font-weight: @regular;
    color: @service-gray;
    padding-top: 1rem;
  }
}
.modal {
  padding-bottom: 2rem !important;
  height: 600px !important;
  background: linear-gradient(to right, #c9e4df, #ffe8ca);
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  margin-bottom: 0;
  padding: 0;
}

.money-svg {
  padding-right: 2rem;
}

.small-card {
  padding: 0.25rem 0.5rem;
  width: 86%;
  cursor: pointer;
}

.pending-count {
  color: @service-gray;
  font-weight: 400;
  font-size: @small;
}

.claim-later {
  margin-left: 1rem;
}

.drawer-noreward {
  margin: 0;
  padding: 1rem;
  width: 100%;
}

.previous-arrow {
  margin: 0.3rem 0 0 0.55rem;
}

.close-btn {
  position: absolute;
  bottom: 1.25rem;
  width: 12rem;
}

.prev-title {
  margin-bottom: 2.5rem;
}

.close-icon {
  color: @service-gray;
  margin-left: 7.5rem;
  cursor: pointer;
}

.failed {
  color: #f34d4d;
  background-color: #fddbdb;
  margin-left: 0.75rem;
}

.claimed {
  color: #828786;
  background-color: #e6e7e7;
  margin-left: 0.75rem;
}

.divider {
  margin: 1rem 0;
}

.trophy-text {
  padding-top: '1rem';
}

.form-data {
  padding-top: 2rem;
}

.success-lottie {
  padding: 4rem 4rem 0 4rem;
}

.claim-success-text {
  padding-top: 1rem;
}

.done-btn {
  margin-bottom: 4rem;
}

.otp-timer {
  font-size: @medium;
  font-weight: @bold;
  margin-top: 1.5em;
  margin-bottom: 0.8em;
}

.contact-help {
  margin-top: 2rem;
}

.otp-btns {
  margin-top: 2rem;
}

@primary-color: #278977;