/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap'); */

.start-trail-main-section {
  margin: 1.25em 0;
  width: 100%;
  background: linear-gradient(
    274.91deg,
    rgba(255, 217, 166, 0.43) -40.46%,
    rgba(187, 211, 247, 0.43) 123.51%
  );
  padding: 2em;
  border-radius: 4px;
}

.start-trial-sub-text {
  color: #363e3d;
  line-height: 2;
}

.btn-full-width {
  width: 100%;
}

.card__description {
  color: #7c7c7c;
  font-family: 'SatoshiMedium';
  font-weight: 400;
  line-height: 1.5em;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .card__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.card__title {
  font-weight: 700;
  color: #040e0c;
  font-family: 'SatoshiBold';
  font-size: 20px;
}

.new-release-tag {
  background: linear-gradient(271.81deg, #045142 2.2%, #b6da25 173.37%);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5em 1em;
  margin-top: 1.5em;
  margin-right: 1.2em;
}

@media (max-width: 1600px) {
  .card__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
