.enquiries-table-title-row {
  margin-top: 1.5em;
}
.enquiries-table-summary-sort {
  margin-top: 1.75em;
}
.enquiries-table-row {
  margin-top: 1.75em;

  .ant-table-thead {
    .ant-table-cell {
      background-color: #f2f3ec;
    }
  }
}

.create-enquiry-modal {
  padding: 1.875em 1.25em;
  .form-row {
    margin-top: 1em;
  }
}
.back-button {
  font-size: 1.7em;
  background: #e6e7e7;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.enquiry-details {
  padding: 20px;
  background-color: #f1f7ff;
  margin-top: 1.2em;
  border-radius: 0.25em;
}
.form-row {
  margin-top: 2em;
}
.space {
  margin-top: 1em;
}
.quotation-selected-product {
  margin-top: 1em;
  margin-right: 1em;
  img {
    border-radius: 0.25em;
  }
  .close-icon {
    cursor: pointer;
    font-size: 22px;
    color: black;
    position: absolute;
    top: 2px;
    right: 2px;
    &:hover {
      color: #666666;
    }
  }
  .product-id {
    margin-top: 0.5em;
  }
}
.image-container {
  border-radius: 4px;
  padding: 4px;
  width: 5.8em;
  height: 5.8em;
  object-fit: cover;
  border: 1px solid #ddd;
}
.products-container {
  height: 31.25em;
  overflow-y: scroll;
}
.image-col {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 4px;
}
.download-button {
  padding: 5px;
  border: 1px solid #b4b7b6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #686e6d;
}
.attribute-title-row {
  background: #f5f5f5;
  padding: 0.5em;
  width: 100%;
  margin-left: 0;
  margin-top: 0.5em;
}
.modal-attribute-container {
  padding: 0.5em;
  margin-top: 0.5em;
}
.customise-modal-header {
  background: white;
  z-index: 100;
}

th.product-info-column {
  background: #f2f3ec !important;
  font-weight: 900 !important;
}

th.basic-details-column {
  background: #c9e4df !important;
  font-weight: 900 !important;
}
th.product-packaging-column {
  background: #c9e4df !important;
  font-weight: 900 !important;
}
th.product-price-column {
  background: #fff0db !important;
  font-weight: 900 !important;
}
th.product-size-column {
  background: #c9e4df !important;
  font-weight: 900 !important;
}
th.inner-product-carton-column {
  background: #c9e4df !important;
  font-weight: 900 !important;
}
th.master-product-carton-column {
  background: #f7fde6 !important;
  font-weight: 900 !important;
}

.reference-image-upload {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
    border: none;
    background: white;
    margin-top: 0.5em;
  }
}

.reference-image-empty-card {
  padding: 4em 3em;
  border: 1px dashed #9b9f9e;
  cursor: pointer;
  min-height: 19.5em;
}

.reference-img-empty-card-sub-row {
  text-align: justify;
  text-justify: inter-word;
}

.reference-img-empty-card-sub-text {
  text-align: center;
  word-wrap: break-word;
}

.drawer-spinner-row {
  position: relative;
  top: 50%;
}

@primary-color: #278977;