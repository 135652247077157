.agr-label {
  color: #686e6d;
  font-size: 12px;
}

.agr-header {
  color: #040e0c;
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
}

.agr-subheader {
  color: #686e6d;
  font-size: 14px;
  margin-top: 4px;
}

.policy-container {
  width: 100%;
  margin-top: 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 32px;
  height: 296px;
  padding: 20px 16px;
  background-color: #f5f5f5;
}

.ack-section {
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ack-statement {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363e3d;
    font-size: 14px;
    font-weight: bold;
  }
}

.cta-container {
  margin-top: 40px;
}

@primary-color: #278977;