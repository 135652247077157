@import '~antd/dist/antd.less';

// @font-face {
//   font-family: 'DM Sans', sans-serif;
//   src: local('DM Sans'),
//     url('./assets/fonts/Satoshi-Medium.woff2') format('woff2');
// }

@font-face {
  font-family: 'SatoshiBold';
  src: url(./assets/fonts/Satoshi-Bold.woff) format('woff');
}
@font-face {
  font-family: 'SatoshiRegular';
  src: local('SatoshiRegular'),
    url(./assets/fonts/Satoshi-Regular.woff) format('woff');
}

@font-face {
  font-family: 'SatoshiMedium';
  src: local('SatoshiMedium'),
    url(./assets/fonts/Satoshi-Medium.woff) format('woff');
}
@mobile: ~'only screen and (max-width: 700px)';
body {
  margin: 0;
  font-family: 'SatoshiMedium';
}

#multiSelectcheckbox .ant-checkbox-inner {
  border-color: #b4b7b6;
}

#multiSelectcheckbox .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #278977;
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border-radius: 8px;
// }
.ant-btn {
  border-radius: 4px;
}
.ant-modal-content {
  border-radius: 4px;
}
.ant-select {
  border-radius: 4px;
}
.ant-input {
  border-radius: 4px;
}
.ant-modal {
  border-radius: 4px;
}
.ant-form-item-label {
  color: #363e3d;
}
.ant-typography {
  color: #595959;
}

.ant-tag-checkable-checked {
  background: #f0f8f6;
  color: black;
  border: 1px solid #278977 !important;
  border-radius: 4px;
}
.ant-collapse-item.site-collapse-custom-panel {
  background: #fbfbfb;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
}
.customise-fields-tabs {
  .ant-tabs-tab {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #f0f8f6;
  }
  .ant-tabs-tab {
    font-size: 16px;
  }
}
.ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left.site-collapse-custom-collapse {
  background: white;
}
.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
  display: none;
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.category-card {
  margin-right: 1rem;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;

  opacity: 0;
  animation: fade-in 0.5s ease-in-out forwards;
}
.ant-tabs-tab {
  padding: 8px 0px !important;
}
.category-card:hover {
  background-color: #f0f8f6;

  .category-card-icon {
    color: #fff;
    background-color: #0b7764;
    border-radius: 50%;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.product-card {
  margin-right: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  min-height: 250px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0;
  animation: fade-in 1s forwards;
  // border:none;
  border: 1px solid #e6e7e7;
  img {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: 1px solid #e6e7e7;
    border-bottom: 0px;
  }
  .ant-card-body {
    padding: 20px;
  }
  .product-id {
    margin-bottom: 0.2rem;
    font-size: 1rem;
    color: #363e3d;
  }

  .product-price {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
  .product-name {
    font-weight: bolder;
    margin-bottom: 0.2rem;
  }
  .product-colors {
    margin-bottom: 0.2rem;
  }
  .product-dimensions {
    margin-bottom: 0.2rem;
  }
}

.date_created {
  background: #f0f8f6;
  border-radius: 0.5em;
  font-family: 'Avenir-Roman';
  font-style: normal;
  font-weight: 400;
  color: #828786;
  font-size: 0.8em;
  /* Inside auto layout */
  padding: 3% 7%;
  margin-bottom: 0.2em;
  min-width: min-content;
}

.product_modal_date_created {
  border-radius: 0.5em;
  font-family: 'Avenir-Roman';
  font-style: normal;
  font-weight: 400;
  color: #828786;
  font-size: 0.8em;
  border: '2px solid #f0f8f6';
  /* Inside auto layout */
  padding: 0.6em 0em;
  margin-top: 1rem;
  // margin-bottom: 0.2em;
  min-width: fit-content;
}

// div{
//   border: 1px solid red;
// }

.row_container {
  display: list-item;
  margin-top: 1em;
}

.product_modal_date_container {
  margin-top: 1em;
  margin-bottom: 1em;
  gap: 1%;
}

.filters {
  @media @mobile {
    display: none;
  }
}

.search {
  @media @mobile {
    display: none;
  }
}

.sort {
  @media @mobile {
    display: none;
  }
}

.editable-cell {
  position: relative;
}

// .ant-table-column-sort {
//   background-color: rgb(239, 148, 148) !important;
// }

.invalid-row {
  background-color: #fddbdb !important;
  // position: sticky !important;
}

.table-sub-row {
  background-color: #f5f5f5 !important;
}

.ant-table-column-sort-invalid {
  background-color: #fddbdb;
}

.editable-cell-value-wrap-first {
  padding: 5px 12px;
  cursor: pointer;
  border: 3px solid purple;
  border-radius: px;
  min-height: 30px;
}

// .ant-table-column-sort {
//   background-color: rgb(239, 148, 148) !important;
// }

.invalid-row {
  background-color: #fddbdb !important;
  // position: sticky !important;
}

.ant-table-column-sort-invalid {
  background-color: #fddbdb;
}

.editable-cell-value-wrap-first {
  padding: 5px 12px;
  cursor: pointer;
  border: 3px solid purple;
  border-radius: 4px;
  min-height: 30px;
}

// .ant-table-column-sort {
//   background-color: rgb(239, 148, 148) !important;
// }

.invalid-row {
  background-color: #fddbdb !important;
  // position: sticky !important;
}

.ant-table-column-sort-invalid {
  background-color: #fddbdb;
}

.editable-cell-value-wrap-first {
  padding: 5px 12px;
  cursor: pointer;
  border: 3px solid purple;
  border-radius: 4px;
  min-height: 30px;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid #b4b7b6;
  border-radius: 4px;
  min-height: 30px;
}
.editable-cell-value-wrap-no-border {
  padding: 5px 12px;
  cursor: none;
  border: none;
  min-height: 30px;
}

.disabled-editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: not-allowed;
  background-color: #efefef;
  border: 1px solid #b4b7b6;
  border-radius: 4px;
  min-height: 30px;
}

.editable-cell-value-wrap-invalid {
  padding: 5px 12px;
  cursor: pointer;
  border: 1.5px solid salmon;
  border-radius: 4px;
  min-height: 30px;
}

.notification-invalid-cells {
  min-width: 450px;
  border-radius: 4px;
  padding: 2em 2em;
  background: linear-gradient(95.81deg, #fddbdb -3.19%, #ffe8ca 104.6%);
}

.editable-cell-value-wrap-invalid {
  padding: 5px 12px;
  cursor: pointer;
  border: 1.5px solid salmon;
  border-radius: 4px;
  min-height: 30px;
}

.notification-invalid-cells {
  min-width: 450px;
  border-radius: 4px;
  padding: 2em 2em;
  background: linear-gradient(95.81deg, #fddbdb -3.19%, #ffe8ca 104.6%);
}

.editable-cell-value-wrap-invalid {
  padding: 5px 12px;
  cursor: pointer;
  border: 1.5px solid salmon;
  border-radius: 4px;
  min-height: 30px;
}

.notification-invalid-cells {
  min-width: 450px;
  border-radius: 4px;
  padding: 2em 2em;
  background: linear-gradient(95.81deg, #fddbdb -3.19%, #ffe8ca 104.6%);
}
// .editable-cell-value-wrap-disabled {
//   background-color: #fbfbfb;
//   border: 0px;
//   color: yellow;
// }

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #b4b7b6;
  border-radius: 4px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
  border-radius: 4px;
}
.product-cart-image {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.buyer-header {
  @media @mobile {
    display: none;
  }
}
.buyer-home-cta {
  width: 100%;

  @media @mobile {
    width: 100%;
  }
}
.buyer-home-title {
  font-size: 2rem;
  @media @mobile {
    font-size: 1.5rem !important;
    margin-top: 1rem !important;
  }
}

.filter-panel {
  background-color: white;
}

.ant-upload.img {
  width: 15rem !important;
  height: 15rem !important;
}

.ant-upload {
  // width: 15rem !important;
  // height: 15rem !important;
}
.ant-upload.upload-more-images {
  height: 2rem !important;
}

.ss-upload-image {
  .ant-upload-list-picture-card-container {
    width: 15rem;
    height: 15rem;
  }
}

.bulk-edit-card {
  width: 625px;
  padding: 1.3rem;
  margin: 0.5rem;
  border-radius: 8px;
  box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.21);
  position: absolute;
  z-index: 100;
  overflow: auto;
  background: rgb(255, 255, 255);
}

.scroll-container::-webkit-scrollbar {
  width: 12px;
  color: red;
}

// .ant-select-selector{
//   width: 100%;
//   height: 64px !important;
//   display: flex;
//   flex-direction: row;
//   align-content:  center ;
//   justify-content: center;
// }
.collection-header {
  position: fixed;
  background: white;
  width: 100%;
  z-index: 100;
}

.small-checkbox {
  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border: 1px solid #686e6d;
    &::after {
      width: 4px;
      height: 8px;
    }
    &::hover {
      border: 1px solid #686e6d;
    }
  }
}
.select-product {
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #686e6d;
    &::after {
      width: 8px;
      height: 12px;
    }
    &::hover {
      border: 1px solid #686e6d;
    }
  }
}
.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  &::after {
    width: 8px;
    height: 12px;
  }
}

// input hide arrows

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-form-item-label > label {
  font-size: 16px !important;
}

// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }

ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
.mask {
  opacity: 0.7;
}
.cta {
  width: 100%;
  background-color: #1d2624;
  border: 0;
  color: #fff;
  height: 48px !important;
  @media @mobile {
    width: 100%;
    height: 48px;
    font-size: 16px;
  }
  &:hover {
    background-color: #040e0c;
    color: #fff;
  }
}

.arrow {
  top: 0;
  margin-top: -10px;
  opacity: 0.9;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #1d2624;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tourCard {
  margin: 0em;
  animation: fadeIn 0.3s;
}

.download-menu-card {
  width: 15em;
  height: 7.5em;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  padding: 0px !important;
}

.table-control {
  color: #4f5655;
  &:hover {
    color: #0b7764;
  }
}

.more-options-menu-item {
  color: #686e6d;
  cursor: pointer;

  &:hover {
    color: #0b7764;
  }

  .menu-item-title {
    font-size: 16px;
    margin-bottom: 2px !important;
    color: #000;
  }
  .menu-item-subtitle {
    font-size: 12px;
    color: #7c7c7c;
  }
}
.ais-Pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  list-style: none;
  padding-left: 0px;

  li {
    margin: 0px 5px;
    a {
      color: #000000d9;
      box-sizing: border-box;
      border-radius: 2px;
      display: inline-block;
      min-width: 1.8em;
      height: 1.8em;

      padding: 2px 8px;
      text-align: center;
      vertical-align: middle;
      background-color: #fff;
      border: 1px solid #d9d9d9;

      &:hover {
        color: #0b7764;
        border: 1px solid #0b7764;
        background: #f0f8f6;
      }
    }
  }
}

.ais-Pagination-link.ais-Pagination-link--selected {
  color: #fff;
  background: #040e0c;
  border: 1px solid #040e0c;
}
.ais-Pagination-item.ais-Pagination-item--disabled {
  cursor: not-allowed;
  color: #d9d9d9;
}

.catalogue-filter-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.catalogue-filter-checkbox .ant-checkbox-inner:after {
  width: 6px;
  height: 10px;
}

.catalogue-search-input {
  height: 38px;
  border-radius: 5px;
}

.filter-text {
  font-size: 14px;
  padding: 2px;
}
.catalogue-history-table {
  .ant-table-thead > tr > th {
    // color: white;
    background-color: #eceee7;
    font-size: 14px;
    font-weight: 500;
    // border-bottom: 1px solid #d9d9d9;
    line-height: 0.7;
    align-items: center;
  }
}

.copy-catalogue-button {
  &:hover {
    cursor: pointer;
  }
}

.link-setttings-btn {
  color: gray;

  &:hover {
    color: #0b7764;
  }
}

.buyer-activity-btn {
  width: 150px;
  background-color: #f0f8f6;
  color: #0b7764;
  padding: 0;
  border: 1px solid #0b7764;
  border-radius: 8px;
  font-weight: 1000;
  font-size: 12px;
  &:hover {
    cursor: pointer;
    background: #363e3d;
    color: #f0f8f6;
    border: none;
  }
}

.buyer-activity-btn.shortlisted {
  background-color: #fff;
  color: #363e3d;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-weight: 1000;
  &:hover {
    cursor: pointer;
    background: #363e3d;
    color: #f0f8f6;
    border: none;
  }
  // &:hover {
  //   cursor: pointer;
  //   background-color: #f5f5f5;
  // }
}

.activity-button-text {
  color: #0b7764;
  font-size: 16px;
}
.buyer-activity-btn.shortlisted .activity-button-text {
  color: #363e3d;
}

.product-card-analytics {
  margin-right: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  min-height: 150px;
  object-fit: cover;
  cursor: pointer;
  // border:none;
  border: 1px solid #e6e7e7;
  img {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: 1px solid #e6e7e7;
    border-bottom: 0px;
  }

  .product-id {
    margin-bottom: 0.2rem;
  }
}

.product-card-analytics .ant-card-body {
  padding: 10px 24px;
}

.product-card-analytics-shortlisted .ant-card-body {
  min-height: 72px;
}

.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sz-filelist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 4;
  justify-content: space-between;
  // border: 1px solid @primary-color;;
  background: #f5f6f7;
  border-radius: 8px;

  .sz-filelist-icon {
    color: @primary-color;
    opacity: 0.8;
    font-size: 20px;
    cursor: pointer;
  }
  .sz-filelist-icon-delete {
    color: #b4b7b6;
    opacity: 0.8;
    font-size: 20px;
    cursor: pointer;
  }
}

.sz-file-card {
  border: 1px solid #e6e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px;
  padding: 12px;

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

////////

.magic-search-drawer {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
  .ant-drawer-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .ant-drawer-header {
    padding-bottom: 0.5rem;
    border-bottom: 0;
  }

  .ant-drawer-body {
    padding-top: 0;
  }
}

////////

.dashoard-slide {
  background: #7db8ad;
  height: 0.75em;
  width: 0.75em;
  border-radius: 50%;
  margin-right: 0.5em;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  border: 0;
}
.dashoard-slide.active {
  border: 3px solid #ffffff;
  height: 1.2em;
  width: 1.2em;
}
.recommendation-card {
  margin-top: 1.2em;
  .recommendation-card-title {
    font-size: 1.2em;
    margin: 0px;
  }
  .recommendation-card-save {
    padding: 5px 10px;
    //  background: rgba(4, 14, 12, 0.4);
    //  color: white;
    border-radius: 32px;
    cursor: pointer;
  }
  .recommendation-card-tags-container {
    margin-top: 1em;
  }
}

.contact-details {
  background: #f8faf7;
  min-height: 40em;
  border-radius: 8px;
}

.algolia-table-select-all .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: '#327AE7';

  &::after {
    width: 5px;
    height: 9px;
    background: '#327AE7';
  }
}

// .algolia-table-select-all .ant-checkbox-inner:after {
//   width: 6px;
//   height: 10px;
// }

.algolia-table-select-all {
  margin-left: 20px;
}

.bulk-upload-steps .ant-steps-item-icon {
  background: #cdcfce;
  border: 1px solid #cdcfce;
  color: #9b9f9e;
}
.bulk-upload-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #9ac421;

  .ant-steps-icon {
    color: white;
  }
}

.bulk-upload-menu .ant-dropdown-menu-item-disabled {
  background: #f1f1f1;
}

.custom_date_dropdown
  *.ant-picker-panels
  > *:first-child
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.custom_date_dropdown
  *.ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.custom_date_dropdown *.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.custom_date_dropdown *.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.custom_date * {
  height: 0;
  width: 0;
  visibility: hidden;
}

.text_filters {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Gray/11 */
  color: #1d2624;
}

.clear-button {
  background: #ffffff;
  color: #4f5655;
  border: 1px solid #cdcfce;

  &:hover {
    background: #f5f5f5;
    color: #363e3d;
  }
}

.view-button {
  background: #1d2624;
  color: #ffffff;
  border: 1px solid #000000;

  &:hover {
    background: #000000;
  }

  &:disabled {
    background: #9b9f9e;
    cursor: not-allowed;
    border: none;
  }
}

.clear-filter-horz {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: white;
  padding: 9px 16px;
  border-radius: 36px;
  color: #14453c;
  font-weight: 400;
  border: 1px solid #b4b7b6;
  cursor: pointer;
  width: '7em';
}

// .bulk-upload-menu .ant-dropdown-menu-item-disabled:hover {
//   cursor: not-allowed !important;
//   background: #9b9f9e;
// }

// .ant-checkbox {
//   top: 0.4em;
// }

.ss-submit-button {
  padding-top: 2em;
  display: flex;
  background: rgb(255, 255, 255, 0.75);
  width: 420px;
  position: absolute;
  padding-bottom: 1em;
  z-index: 100;
  bottom: 0;
}

.ss-drawer-subtitle {
  color: #4f5655;
  font-size: 1rem;
  font-weight: normal;
  padding-bottom: 0.5rem;
}

.ss-selected-info {
  display: flex;
  background-color: #bbd3f7;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem;
  margin: 1rem 0;
  .ss-selection-count {
  }

  .ss-cancel-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    gap: 0.3rem;
    cursor: pointer;
    font-weight: bold;
  }
}
.ss-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  p {
    font-weight: bold;
  }
}

.image-selection-container {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  .ss-image-container {
    aspect-ratio: 1/1;
    display: flex;
    position: relative;
    cursor: pointer;
    border: 2px solid #d3d3d3;
    border-radius: 3px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .ss-checkbox {
      position: absolute;
      z-index: 2;
      top: 2px;
      left: 5px;
    }
  }
}

.manage-products-dropdown {
  z-index: 3;
}
.delete-category-modal .delete-category-radio .ant-radio {
  display: none;
}
//catalogue collection select products
.catalogue-div {
  border-bottom: 1px solid #e6e7e7;
}
.catalogue-heading {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Avenir';
}
.exclusive-title {
  font-size: 1.1rem;
  font-weight: bold;
}
.exclusive-btns {
  display: flex;
  justify-content: space-between;
}
.remove-all {
  height: 2.4rem;
}

.detail-container-internal {
  border: 1px dashed #b4b7b6;
  padding: 1em;
}

.new-release-tag-small {
  background: linear-gradient(271.81deg, #045142 2.2%, #b6da25 173.37%);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.2em 0.5em;
  // margin-top: 1.5em;
  margin: 0 0.3em;
}

.variants-disabled-btn {
  background: #e6e7e7;
  color: #9b9f9e;
  border: none;
  cursor: auto;

  &:hover {
    background: #e6e7e7;
    color: #9b9f9e;
    border: none;
    cursor: auto;
  }
}

.variants-publish-btn {
  background: #0b7764;
  color: #fff;
  border: #0b7764;

  &:hover {
    background: #0b7764;
    color: #fff;
    border: #0b7764;
  }
}

.radio-access .ant-radio-inner::after {
  background-color: #f34d4d;
}

.radio-access .ant-radio-checked .ant-radio-inner {
  border-color: #f34d4d;
}

.form-control-input-number {
  width: 100%;
}

.all-products-search {
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  .ant-input-group-addon {
    border-radius: 20px;
    padding: 0px;
    left: 0 !important;
    &:hover {
      border: 1px solid #d9d9d9;
    }
  }
  span {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;

    button {
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
      border-left: none;

      &:hover {
        border: 1px solid #d9d9d9;
      }
    }
  }
}

@primary-color: #278977;