.text-bold {
  font-weight: bold;
}
.small-text {
  font-size: 0.75rem;
  color: #4f5655;
  margin-top: 0.5rem;
}
.breakdown-total-row {
  border-top: 1px solid #e6e7e7;
  padding-top: 1.2rem;
}
