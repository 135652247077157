[data-theme='compact']
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 0px;
  overflow: hidden;
  background: #ffffff;
  border: 0px;
  border-radius: 2px;
  margin: 0px;
}

.site-collapse-custom-collapse {
  padding: 0;
  margin: 0px;
  background: #ffffff;
  border: none;
  margin-top: 16px;
  margin-bottom: 100px;
}

.site-collapse-custom-panel .ant-collapse-header {
  padding: 0px !important;
  font-size: 16px;

  border-radius: 4px;
}

.site-collapse-custom-panel {
  border: 1px solid #d9d9d9 !important;
  margin-bottom: 16px !important;
}

.site-collapse-custom-collapse .ant-checkbox-inner {
  height: 14px;
  width: 14px;
}

.site-collapse-custom-collapse .ant-checkbox-inner::after {
  left: 15% !important;
  top: 40% !important;
}
.sw-multi-select-content {
  position: absolute;
  margin-top: 8px;
  z-index: 100;
  background: #ffffff;
  min-width: 250px;
  max-width: 360px;
  /* padding: 8px; */

  overflow: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-height: 40vh;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.sw-multi-select-content .ais-RefinementList-item {
  /*border-bottom: 1px solid #d9d9d9;*/
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
  cursor: pointer;
}
.ais-RefinementList-list {
  overflow: scroll;
}
.sw-multi-select-content ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.sw-multi-select-content li:last-of-type {
  border: none;
}

.sw-multi-select-content .ant-checkbox-inner {
  height: 16px;
  width: 16px;
}

.range-content {
  width: 100% !important;
  padding: 10px !important;
  padding-top: 5px !important;
}

.date-content {
  width: 100% !important;
  padding: 10px !important;
  padding-top: 5px !important;
  max-height: 45vh;
}

.sw-select-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.sw-collapse-refinement {
  border: 1px solid #cdcfce;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.sw-collapse-refinement-header {
  cursor: pointer;
  font-size: 16px;
}

.sw-collapse-refinement ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #cdcfce;
  padding-top: 4px;
  margin-top: 8px;
}

.sw-collapse-refinement li {
  border-bottom: 1px solid #e6e7e7;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 4px;
}

.sw-collapse-refinement li:last-of-type {
  border-bottom: none;
}
.sw-collapse-refinement .ant-checkbox-inner {
  height: 16px;
  width: 16px;
}

div.sw-tablewrapper {
  max-height: calc(100vh - 160px);
  min-height: 60vh;
  overflow-y: scroll;
  margin-top: 16px;
}

table.sw-table-hit-render {
  /* background: red; */
  width: 100%;
  min-width: 100%;
  overflow: scroll;
  width: 100%;
}

table.sw-table-hit-render tbody {
  font: optional;
}

table.sw-table-hit-render img {
  max-width: 80px;
}
table.sw-table-hit-render thead {
  background: #f2f3ec;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  border-radius: 16px;
  overflow-x: scroll;
  position: sticky;
  top: 0;
  z-index: 98;
}
.sw-table-hit-render {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
}

table.sw-table-hit-render th:first-child {
  border-radius: 2px 0 0 2px;
}

table.sw-table-hit-render th:last-child {
  border-radius: 0 2px 2px 0;
}

thead.sw-table-hit-render-head {
  border-radius: 4px;
}
.sw-table-hit-render-head th {
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
}
th.sw-table-hit-render-th {
  font-weight: bold;
  color: #686e6d;
  width: 200px;
}
table.sw-table-hit-render td {
  vertical-align: top;
  padding: 8px;
  min-width: 100px;
}
.sw-table-hit-render-body-tr {
  border-bottom: 1px solid #cdcfce;
}

.disabled-text {
  color: #cdcfce;
  font-weight: 400;
  font-style: italic;
}

table.sw-table-hit-render .ant-tag {
  margin: 2px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
  max-width: 100px;
  display: inline-block;
}

.sw-drawer .ant-drawer-body {
  padding-bottom: 100px;
}

/* div {
  border: 1px dotted red;
} */

.sw-sidebar .ant-menu-title-content {
  margin-top: 4px;
  /* background-color: red; */
}
@media screen and (min-height: 600px) {
  .sw-sidebar-bottom {
    /* height: calc(100vh - 450px);
    padding-bottom: 25px; */
    position: absolute;
    bottom: 70px;
    border-top: 0px;
  }
}

.primaryMenu {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.primaryMenu::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sw-sidebar-content {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.sw-sidebar-container .sw-sidebar-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.sw-sidebar .ant-menu-item-selected {
  background-color: #42611f !important;
}

.sw-sidebar .ant-menu-item {
  color: #434343 !important;
}

::-moz-selection {
  /* Code for Firefox */
  background: #42611f;
}

::selection {
  background: #42611f;
}

.sw-sidebar-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.sw-new-announcement {
  /* border: 1px solid red; */
  max-width: 300px;
  margin: -8px;
}
.sw-new-announcement h3 {
  font-weight: bold;
}
.sw-more-option-list {
  margin: -12px -16px;
}
.sw-more-option-list li {
  padding: 8px 16px;
  cursor: pointer;
}
.sw-more-option-list li:hover {
  background: #f5f5f5;
}
/* .sw-more-option-td{
  border: 1px solid blue;
  width: 40px;
} */
/* 
td{
  border: 1px solid red;
} */

/* div{
  border: 1px solid red;
} */
table.sw-costing-packaging th {
  width: auto;
}
.sw-product-costing .ant-input[disabled] {
  color: #040e0c;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  cursor: context-menu;
}

.resource-library .ant-input[disabled] {
  color: #040e0c;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  cursor: context-menu;
}

.resource-library
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #040e0c;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  cursor: context-menu;
}
.sw-product-costing
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #040e0c;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  cursor: context-menu;
}

.sw-product-info {
  height: 100px;
  width: 180px;
  background: #f5f5f5;
  margin-right: 4px;
  border-radius: 4px;
  padding-top: 8px;
}
.sw-product-info-container {
  padding-left: 12px;
  display: inline-block;
}
.sw-product-info-title {
  padding-top: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  padding-left: 8px;
}

.sw-product-info-value {
  /* font-size: 18px; */
  font-weight: bold;
  padding-left: 8px;
}
.sw-costing-table-container {
  padding: 16px;
  border: 1px solid #e6e7e7;
  margin-top: 20px;
  border-radius: 4px;
}

table.sw-costing-table {
  /* background: red; */
  width: 100%;
  min-width: 100%;
  overflow: scroll;
  width: 100%;
}

table.sw-costing-table thead {
  border-bottom: 1px solid #e6e7e7;
}

table.sw-costing-table th {
  font-weight: bold;
  text-align: left;
  padding-bottom: 8px;
  padding-left: 8px;
}
table.sw-costing-table td {
  padding-left: 8px;
  padding-bottom: 4px;
}
tbody.sw-costing-table-tbody td {
  padding-top: 8px;
}
tr.sw-headline-row td {
  font-weight: bold;
}
tr.sw-table-subtotal-row td {
  background: #f0f8f6;
  padding-top: 8px;
  padding-bottom: 4px;
  margin-top: 8px;
}
tr.sw-part-table-row {
  font-weight: 600;
}
td.sw-subtotal-cost {
  font-weight: bold;
  color: #0b7764;
}
tr.sw-table-costing-end td {
  padding-top: 30px;
  padding-bottom: 10px;
  background: #f5f5f5;
  font-weight: bold;
}
td.sw-table-costing-selling-price {
  font-size: 24px;
}
td.sw-table-costing-selling {
  font-size: 16px;
}
table.sw-costing-table tr td:last-child {
  text-align: right;
  padding-right: 16px;
}
tr.sw-headline-row td {
  text-align: left;
}

.filter-selected {
  border: 1px solid #4f5655;
}

.filter-common {
  /* height: 2.9em; */
  text-align: initial;
  cursor: pointer;
}

/* code for sidebar transition effect */

/* .sidebar-enter {
  width:7.45%;
}
.sidebar-enter-active {
  width:17.8vw;
  transition: width 100ms;
}
.sidebar-exit {
  width: 20vw;
}
.sidebar-exit-active {
  width:5.55vw;
 transition: width 100ms
} */

.hide-sidebar-container {
  width: 80px;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 10;
  position: fixed;
  z-index: 101;
  overflow: visible;
  background-color: #F7F7F7;
  transition: 200ms;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
}

.show-sidebar-container {
  width: 250px;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 10;
  position: fixed;
  z-index: 101;
  overflow: visible;
  background-color: #F7F7F7;
  transition: 200ms;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
}
