.success-container {
  width: 100%;
  height: 379px;
  padding-top: 110px;
}

.circle {
  position: relative;
  background-color: #7db8ad;
  border-radius: 50%;
  width: 50px;
  margin: auto;
  height: 50px;

  .checkmark {
    position: absolute;
    transform: rotate(45deg) translate(-50%, -50%);
    left: 35%;
    top: 45%;
    height: 20px;
    width: 10px;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
  }
}

.success-title {
  color: #040e0c;
  font-weight: 700;
  font-size: 20px;
  margin-top: 28px;
  text-align: center;
}

.success-subtitle {
  color: #686e6d;
  text-align: center;
  margin-top: 8px;
}

@primary-color: #278977;