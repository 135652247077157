.help-desk-active-div {
  display: flex;
  justify-content: space-between;
}
.help-desk-active-subdiv {
  margin-top: 1.8rem;
}
.help-desk-active-support {
  font-weight: bold;
}
.help-desk-demo-text {
  color: #363e3d;
}
