.early-bird-col {
  margin-top: 1rem;
  /* margin-left: 0.2rem; */
}
.early-bird-subtitle-row {
  width: 60%;
}
.early-bird-subtext-row {
  margin-left: 0.7rem;
}
.early-bird-subtitle {
  color: #363e3d;
  font-size: 0.95rem;
}
.early-bird-subtext {
  color: #1d2624;
  font-size: 1rem;
  line-height: 2rem;
}
