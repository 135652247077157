.login-notification {
  background: #fddbdb;
  border-radius: 8px;
  width: 34rem;
  padding: 1rem;
}
.task-failed {
  margin-right: 9px;
  margin-bottom: 4px;
}

.tasks-view-details {
  background: #f0f8f6;
  border: 1px solid #6dada2;
  border-radius: 4px;
  color: #0b7764;
  margin-left: '4px';
}

.taskButtonDisable {
  color: #9b9f9e !important;
  background-color: #e6e7e7 !important;
  border: 1px solid #9b9f9e !important;
  border-radius: 4px !important;
}

.footer-gap {
  height: 80px;
}

.notification-wrap {
  margin-bottom: 16px;
  &_sm-text {
    font-size: 12px;
  }
  &_head-div {
    width: 100%;
    &_sub {
      margin-bottom: 8px;
    }
    &_img {
      margin-right: 10px;
    }
    &_txt {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
    }
    &_sub-txt {
      font-size: 14px;
    }
  }
  &_btn {
    background: #1d2624;
    border-radius: 4px;
    color: #ffffff;
    padding: 8px 16px;
    height: 40px;
    float: right;
  }
}

@primary-color: #278977;