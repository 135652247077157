.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;
}

.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: none;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: white;
}

.ant-select.country_codes_select.ant-select-single.ant-select-show-arrow.ant-select-show-search
  .ant-select-selector {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qr-profile-trial-reminder-col {
  margin-left: 1rem;
  background: linear-gradient(275.19deg, #7db8ad -23.83%, #f7fde6 124.1%);
  border-radius: 1rem;
  padding: 0.1rem 1.1rem 0rem 1.1rem;
  height: 1.57rem;
}
.qr-profile-trial-reminder-text {
  color: #14453c;
}
