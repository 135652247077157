.qrbox {
  background: #ffffff;
  border: 1px solid #b4b7b6;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  min-height: fit-content;

  cursor: pointer;
  &_desc {
    padding: 18px 18px;
    &_listItemStyle {
      color: #4f5655;
      font-size: 14px;
    }
    &_listItemStyle:first-child {
      color: #4f5655;
      font-size: 14px;
      margin-top: 16px;
      margin-top: 16px;
    }
    //  &_listItemStyle: {
    //   color: #4f5655;
    //   font-size: 12px;
    //   margin-bottom: 8px;
    //   margin-top: 16px;
    // }
    // &_listItemStyle:last-child {
    //   color: #4f5655;
    //   font-size: 12px;
    //   margin-bottom: 8px;
    // }
    &_tick {
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }
  &_imgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // width: 142px;
    background: #f5f5f5;
    border-left: 1px solid #b4b7b6;
    // border-bottom: none;
    // border-top: none;
    // border-right: none;
    border-radius: 0px 8px 8px 0px;
    &_eyeView {
      position: absolute;
      top: 7px;
      right: 7px;
      border-radius: 50%;
      // z-index: 999;
    }
    &_preView {
      width: 80px;
      height: 80px;
    }
  }
  &_imgDivActive {
    border-left: 1px solid #0b7764;
    background-color: #f0f8f6;
  }
}

.rowHandler {
  display: flex;
  justify-content: space-between;
  &_one {
    width: 100%;
    margin-right: 5%;
  }
  &_two {
    width: 95%;
  }
}

.qrBoxActive {
  background-color: #f0f8f6;
  border: 1px solid #0b7764;
}

.bottomBarHandler {
  position: fixed;
  // left: 0;
  bottom: 0;
  // width: fit-content;
  // background: red;
}

.bottomContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;

  &_one {
    width: 290px;
    &_btn {
      min-width: 120px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_btn2 {
      min-width: 120px;
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      border: solid 1px #f5f5f5;
      border-radius: 4px;
    }
    &_btn3 {
      width: fit-content;
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      border: solid 1px #f5f5f5;
      border-radius: 4px;
      padding: 8px;
    }
  }
  &_two {
    // width: 100%;
    &_btn {
      background-color: #000;
      border: solid 1px #000;
      min-width: 120px;
      min-height: 40px;
    }
    &_btn:hover {
      background-color: #000;
      border: solid 1px #000;
    }
    &_btnDisab {
      background-color: #f5f5f5;
    }
    &_btnActive {
      background-color: #000;
    }
  }
}

.qrMain {
  justify-content: space-around;
  min-height: 100%;
}
.atrributeSectionLayout {
  background-color: #f5f5f5;
  padding: 14px;
  border-radius: 8px;
}

.qrDrawerTextLarge {
  font-size: 16px;
  color: #000;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.qrDrawerTextLarge2 {
  font-size: 16px;
  color: '#040e0c';
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 14px;
}

.qrDrawerTextLarge2 {
  font-size: 18px;
  color: '#040e0c';
  line-height: 21px;
  font-weight: 600;
  margin-top: 20px;
}

.qrDrawerTextMedium2 {
  font-size: 16px;
  color: #000;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}
.qrbox_desc_listItemStyle {
  color: #7c7c7c;
}

.qrDrawerTextMedium {
  font-size: 14px;
  color: #7c7c7c;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.qrDrawerTextGreen {
  font-size: 14px;
  color: #0b7764;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 8px;
}

.qrDrawerTextsmall {
  font-size: 12px;
  color: #4f5655;
  line-height: 18px;
  font-weight: 400;
  // margin-bottom: 30px;
}

.qrDrawerTextTiny {
  font-size: 10px;
  color: #4f5655;
  font-weight: 700;
  cursor: pointer;
  padding-top: 5px;
  // margin-bottom: 30px;
}

.qrModalContent {
  // display: flex;
  // align-items: center;
  margin-top: 110px;
  margin-bottom: 110px;
}

// .sw-drawer .ant-drawer-body
// {
//   padding-bottom: 0;
// }

.qrlabel {
  overflow-y: auto;
  // height: 620px;
}

.qr-primary-cta {
  background-color: #000;
  color: #fff;
}

.qr-primary-cta:hover {
  background-color: #000;
  color: #fff;
  border: solid 1px #f5f5f5;
}

.qr-primary-cta:focus {
  background-color: #000;
  color: #fff;
  border: solid 1px #f5f5f5;
}

.modalCloseBtnView {
  width: 120px;
  height: 40px;
  background-color: #000 !important;
  color: #fff;
  border: solid 1px #f5f5f5;
}

.modalCloseBtnView:hover {
  background-color: #000 !important;
  color: #fff;
  border: solid 1px #f5f5f5;
}

@primary-color: #278977;