.upgrade-addon-card .ant-card-actions {
  background: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
}
.upgrade-plan-card .ant-card-actions {
  background: none;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.module-upgrade-subtext {
  color: #4f5655;
  width: 230px;
}
.upgrade-module-badge {
  height: 100%;
  width: 100%;
}
.plan-title-label {
  width: 14rem;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
}
/* .ant-badge-count .plan_badge{
    margin-top: 2px;
    margin-right: 85px;
} */
