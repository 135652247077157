.select-bulk-attribute {
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  background: #f5f5f5;
  overflow: scroll;
  height: 97%;
  gap: 1.5rem;
}
.change-template-skeleton {
  width: 100%;
  gap: 1rem;
  flex-direction: column;
}
.change-template-skeleton-item {
  margin-top: 10px;
  border-radius: 8px;
}

@primary-color: #278977;